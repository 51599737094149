<template>
  <div>
    <div class=" mt-5">
      <PageBuscaResultadoTabelaApi
        :quantidade-resultado-por-pagina="[5,10,25,50,100]"
        :url-api="urlApi"
        tipo-api="pública"
        :tabela="camposBusca.tabela"
      />
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';
import buscarEstados from '../../../utils/buscarEstados';
import buscarMunicipiosIBGE from '../../../utils/buscarMunicipiosIBGE';
import PageBuscaResultadoTabelaApi from '../widget/BuscaResultadosTacApi.vue';

export default {
  name: 'BuscaResultadosTacApi',
  components: {
    PageBuscaResultadoTabelaApi,
  },
  mixins: [Vue2Filters.mixin],
  props: {
    tipoApi: {
      type: String,
      default: 'pública',
    },
    urlApi: {
      type: String,
      default: '',
    },
    tituloTabela: {
      type: String,
      default: '',
    },
    tituloBusca: {
      type: String,
      default: '',
    },
    tabela: {
      type: Array,
      default: () => [],
    },
    quantidadeResultadoPorPagina: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tablePlanosLoading: false,
    apiSysAGANovo: '',
    apiSysAGAAntigo: '',
    expanded: [],
    planos: [],
    total: null,
    perPage: null,
    quantidadePaginas: 1,
    totalVisible: 7,
    from: null,
    to: null,
    page: 1,
    estado: '',
    municipio: [],
    aerodromo: '',
    quantidade: 5,
    estados: [],
    municipios: [],
    rules: {
      required: (value) => !!value || 'Required.',
      max100: (value) => (value || '').length <= 100 || 'Max 100 characters',
      min4: (value) => (value && value.length >= 4) || 'Min 4 characters',
      min2: (value) => (value && value.length >= 2) || 'Min 2 characters',
    },
    search: '',
  }),
  computed: {

    camposBusca() {
      const retorno = {};
      retorno.tituloTabela = this.$t('planos.resultados_busca');
      retorno.tabela = [
        {
          item: 'tac',
          text: 'TAC',
          value: 'tac',
          mostrarColuna: true,
          type: 'closure',
          link: {
            estatico: 'tac-portal/',
            key: 'id',
            api: 'sysaga2',
          },
        },
        {
          item: 'aerodromo',
          text: this.$t('tac.resultado.table_headers.nome_aerodromo'),
          align: 'start',
          sortable: true,
          value: 'aerodromo',
          mostrarColuna: true,
          type: 'object',
          filho: 'denominacao',

        },
        {
          item: 'data_assinatura',
          text: this.$t('tac.resultado.table_headers.data_assinatura'),
          align: 'start',
          sortable: true,
          value: 'assinatura_dt',
          mostrarColuna: true,
          type: 'date',
        },

        {
          item: 'validade_dt',
          // text: 'Validade',
          text: this.$t('tac.resultado.table_headers.validade'),
          value: 'validade_dt',
          mostrarColuna: true,
          type: 'date',
        },
        {
          item: 'codOACI',
          text: this.$t('tac.resultado.table_headers.oaci'),
          value: 'codOACI',
          mostrarColuna: true,
          type: 'object',
          filho: 'codOACI',
        },
        {
          item: 'dou',
          text: this.$t('tac.resultado.table_headers.dou'),
          value: 'dou',
          mostrarColuna: true,
          type: 'closure',
        },

      ];
      return retorno;
    },
  },
  mounted() {
    this.estados = [...buscarEstados];
    this.apiSysAGANovo = process.env.VUE_APP_URL_API;
    this.apiSysAGAAntigo = process.env.VUE_APP_URL_SysAGA2;
  },
  methods: {
    clicked(id) {
      console.log('clicked');
      console.log(id);
      if (this.expanded && this.expanded[0] && this.expanded[0].id === id) {
        this.expanded = [];
      } else {
        const achandoLinha = this.planos.filter((cada) => cada.id === id);
        this.expanded = achandoLinha;
      }
    },
    limparFiltros() {
      this.estado = '';
      this.municipio = [];
      this.aerodromo = '';
    },
    mudandoMunicipioDevidoEstado() {
      const { estado } = this;
      if (estado) {
        buscarMunicipiosIBGE(estado).then((retorno) => {
          this.municipios = retorno;
        });
      }
    },
    async buscandoPlanos(estado, municipio, aerodromo, quantidade, page) {
      this.tablePlanosLoading = true;
      const apiurl = `${process.env.VUE_APP_URL_API}/${this.urlApi}`;
      await this.$axios.post(`${apiurl}?page=${page}`, {
        estado,
        municipio,
        aerodromo,
        quantidade,
      })
        .then((response) => {
          const planos = response.data.downloads;
          this.total = planos.total;
          this.perPage = planos.per_page;
          this.from = planos.from;
          this.to = planos.to;
          this.page = planos.current_page;
          this.planos = planos.data;
          this.tablePlanosLoading = false;
          this.quantidadePaginas = Math.ceil(this.total / this.perPage);
          this.totalVisible = this.quantidadePaginas;
          if (this.quantidadePaginas > 7) {
            this.totalVisible = 7;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>

</style>
