<template>
  <div class="fill-height">
    <v-container
      fluid
      class="pa-2 px-sm-10 white"
    >
      <br>
      <h2 class="text-h3 font-weight-bold text-center">
        {{ $t('route.portal.tac.title') }}
      </h2>
      <div class="pa-2 px-sm-10 pt-10 text-center">
        <p
          class="text-h6 font-weight-light"
          style="line-height: 2"
        >
          {{ $t('tac.texto') }}
        </p>
        <p
          class="text-h6 font-weight-light"
          style="line-height: 2"
        >
        <a :href="$t('tac.link_ajuda')" target="_blank">{{ $t('tac.ajuda') }}</a>
        </p>
      </div>
    </v-container>
    <v-container
      fluid
      class="pa-2 pa-sm-10"
    >
      <v-card
        rounded="xl"
        flat
        class="white px-sm-10 pa-12"
      >
        <h3 class="text-h4 mb-10 font-weight-bold text-center">
          Pesquisa de TAC em vigor
        </h3>

        <TacFormulario
          :quantidade-resultado-por-pagina="[5,10,25,50,100]"
          url-api="portal/tacs"
          tipo-api="pública"
          :titulo-tabela="camposBusca.tituloTabela"
          :titulo-busca="camposBusca.tituloBusca"
          :tabela="camposBusca.tabela"
        />
      </v-card>
    </v-container>
  </div>
</template>

<script>
import TacFormulario from './TacFormulario.vue';

export default {
  name: 'Tac',
  components: { TacFormulario },
  data: () => ({
  }),
  computed: {
    camposBusca() {
      const retorno = {};
      retorno.tituloTabela = this.$t('tac.resultados_busca');
      retorno.tituloBusca = this.$t('tac.card.titulo');
      retorno.tabela = [
        {
          item: 'aerodromo',
          text: this.$t('tac.resultado.table_headers.nome_aerodromo'),
          align: 'start',
          sortable: true,
          value: 'aerodromo',
          mostrarColuna: true,
          type: 'object',
          filho: 'denominacao',

        },
        {
          item: 'aerodromo',
          text: this.$t('tac.resultado.table_headers.oaci'),
          value: 'aerodromo',
          mostrarColuna: true,
          type: 'object',
          filho: 'codOACI',
        },
        {
          item: 'tac',
          text: 'TAC',
          value: 'numero',
          mostrarColuna: true,
          type: 'string',
          link: {
            estatico: 'tac-portal/',
            key: 'id',
            api: 'sysaga2',
          },
        },
        {
          item: 'dt_validade',
          // text: 'Validade',
          text: this.$t('tac.resultado.table_headers.validade'),
          value: 'dt_validade',
          mostrarColuna: true,
          type: 'date',
          link: {
            estatico: 'tac-portal/',
            key: 'id',
            api: 'sysaga2',
          },
        },
      ];
      return retorno;
    },
  },
  methods: {
  },
};
</script>

<style scoped>
  body {
    background: red;
  }
</style>
