import axios from 'axios';

export default async (estado) => {
  const apiurl = `${process.env.VUE_APP_URL_API}/portal/tacs/municipios/${estado}`;

  const retorno = await axios.get(apiurl)
    .then((response) => {
      const { data } = response;
      return data.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return retorno;
};
