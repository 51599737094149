<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row
          align="center"
          justify="space-around"
          class="d-flex"
        >
          <v-col
            cols="12"
            sm="4"
            md="2"
            lg="2"
            class="my-0 py-0"
          >
            <div>
              <v-select
                v-model="estado"
                :items="estados"
                label="Estado"
                outlined
                placeholder=" "
                @change="mudandoMunicipioDevidoEstado"
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="3"
            class="my-0 py-0"
          >
            <v-autocomplete
              v-model="municipio"
              :items="municipios"
              multiple
              :disabled="!estado || municipios.length === 0"
              item-text="municipio"
              item-value="municipio"
              label="Município"
              outlined
              :placeholder="estado ? ' ' : 'Selecione o estado'"
            />
          </v-col>
          <v-col
            md="4"
            class="my-0 py-1"
          >
            <v-text-field
              v-model="aerodromo"
              :rules="[rules.min2,rules.max100]"
              label="Aeródromo (Nome, CIAD ou Código ICAO)"
              outlined
              placeholder=" "
            />
          </v-col>
          <v-col
            md="3"
            class="my-0 py-0"
            style="apadding-bottom: 31px !important;"
          >
            <v-btn
              class="py-4 mx-1"
              color="primary"
              dark
              depressed
              @click="buscandoPlanos(estado,municipio,aerodromo,quantidade,1)"
            >
              {{ $t('button.search') }}

              <span
                v-if="planos.length > 0"
                class="ml-2"
              >
                ({{ total }})
              </span>
            </v-btn>
            <v-btn
              class="py-4 mx-1"
              color="warning"
              dark
              depressed
              @click="limparFiltros()"
            >
              {{ $t('button.clear') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-container
          v-if="planos.length > 0 && quantidadePaginas !== 1"
          class="max-width ma-0 pa-0"
        >
          <v-row
            align="baseline"
            justify="space-around"
          >
            <v-col
              cols="auto"
            >
              <v-pagination
                v-model="page"
                class="my-1"
                :total-visible="totalVisible"
                :length="quantidadePaginas"
                @input="buscandoPlanos(estado,municipio,aerodromo,quantidade,page)"
              />
            </v-col>
            <v-col
              cols="auto"
            >
              <v-select
                v-model="quantidade"
                :items="quantidadeResultadoPorPagina"
                label="Quantidade por página"
                solo
                rounded
                @change="buscandoPlanos(estado,municipio,aerodromo,quantidade,page)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card flat>
      <v-card-title>
        <h4 class="text-h6">
          {{ tituloTabela }}
        </h4>
      </v-card-title>
      <v-spacer />
      <template>
        <v-data-table
          :headers="tabela"
          :items="planos"
          item-key="name"
          class="elevation-1"
          single-expand
          disable-sort
          dense
          :loading="tablePlanosLoading"
          :loading-text="$t('planos.resultado.table_loading')"
          hide-default-footer
          :expanded.sync="expanded"
        >
          <template v-slot:item.tac="{item}">
            <a
              :href="`${apiSysAGAAntigo}/tac-portal/${ item.id }`"
              target="_blank"
            >
              {{ item.numero }}/{{ item.assinatura_dt | moment("YYYY") }}
            </a>
          </template>
          <template v-slot:item.aerodromo="{item}">
            {{ item.aerodromo.denominacao }}
          </template>
          <template v-slot:item.assinatura_dt="{item}">
            {{ item.assinatura_dt | moment("DD.MM.YYYY") }}
          </template>
          <template v-slot:item.validade_dt="{item}">
            {{ item.validade_dt | moment("DD.MM.YYYY") }}
          </template>
          <template v-slot:item.codOACI="{item}">
            {{ item.aerodromo.codOACI }}
          </template>
          <template v-slot:item.dou="{item}">
            <template v-if="item.dou_n">
              nº {{ item.dou_n }} de {{ item.dou_dt | moment("DD.MM.YYYY") }}
            </template>
            <template v-else>
              -
            </template>
          </template>
        </v-data-table>
      </template>
    </v-card>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';
import buscarEstados from '../../../utils/buscarEstados';
import buscarMunicipiosAerodromos from '../../../utils/buscarMunicipiosAerodromos';

export default {
  name: 'PortalBuscaResultadoTabelaApi',
  mixins: [Vue2Filters.mixin],
  props: {
    tipoApi: {
      type: String,
      default: 'pública',
    },
    urlApi: {
      type: String,
      default: '',
    },
    tituloTabela: {
      type: String,
      default: '',
    },
    tabela: {
      type: Array,
      default: () => [],
    },
    quantidadeResultadoPorPagina: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tablePlanosLoading: false,
    apiSysAGANovo: '',
    apiSysAGAAntigo: '',
    expanded: [],
    planos: [],
    total: null,
    perPage: null,
    quantidadePaginas: 1,
    totalVisible: 7,
    from: null,
    to: null,
    page: 1,
    estado: '',
    municipio: [],
    aerodromo: '',
    quantidade: 5,
    estados: [],
    municipios: [],
    rules: {
      required: (value) => !!value || 'Required.',
      max100: (value) => (value || '').length <= 100 || 'Max 100 characters',
      min4: (value) => (value && value.length >= 4) || 'Min 4 characters',
      min2: (value) => (value && value.length >= 2) || 'Min 2 characters',
    },
    search: '',
  }),
  computed: {
  },
  async mounted() {
    this.estados = await buscarEstados();
    this.apiSysAGANovo = process.env.VUE_APP_URL_API;
    this.apiSysAGAAntigo = process.env.VUE_APP_URL_SysAGA2;
  },
  methods: {
    clicked(id) {
      if (this.expanded && this.expanded[0] && this.expanded[0].id === id) {
        this.expanded = [];
      } else {
        const achandoLinha = this.planos.filter((cada) => cada.id === id);
        this.expanded = achandoLinha;
      }
    },
    limparFiltros() {
      this.estado = '';
      this.municipio = [];
      this.planos = [];
      this.aerodromo = '';
    },
    mudandoMunicipioDevidoEstado() {
      const { estado } = this;
      if (estado) {
        buscarMunicipiosAerodromos(estado).then((retorno) => {
          this.municipios = retorno;
        });
      }
    },
    async buscandoPlanos(estado, municipio, aerodromo, quantidade, page) {
      this.tablePlanosLoading = true;
      const apiurl = `${process.env.VUE_APP_URL_API}/${this.urlApi}`;
      await this.$axios.post(`${apiurl}?page=${page}`, {
        estado,
        municipio,
        aerodromo,
        quantidade,
      })
        .then((response) => {
          const planos = response.data.downloads;
          this.total = planos.total;
          this.perPage = planos.per_page;
          this.from = planos.from;
          this.to = planos.to;
          this.page = planos.current_page;
          this.planos = planos.data;
          this.tablePlanosLoading = false;
          this.quantidadePaginas = Math.ceil(this.total / this.perPage);
          this.totalVisible = this.quantidadePaginas;
          if (this.quantidadePaginas > 7) {
            this.totalVisible = 7;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
